import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty, Toast, Dialog, Loading, Popup, Collapse, CollapseItem } from 'vant'
import { setTimeout } from 'core-js'

Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Toast)
export default {
  components: {},
  data () {
    return {
      check: true,
      icon: require('../../assets/image/onewei.png'),
      iconcheck: require('../../assets/image/onexuan.png'),
      icon1: require('../../assets/image/gouwei.png'),
      icon1check: require('../../assets/image/onegou.png'),
      iconLeft: require('../../assets/image/line.png'),
      serve: [],
      card: [],
      serveNoUse: [],
      serveUse: [],
      cardNoUse: [],
      cardUse: [],
      show: false,
      isfalse: false,
      activeNames: ['0'],
      activeNames1: ['0']



    }
  },
  created () {
    this.getList()



  },
  methods: {
    ...mapActions('equityCenter', ['getequityCenter', 'activequityCenter', 'getEquityDetail']),
    getList () {
      this.getequityCenter({ isLate: 0 }).then(res => {
        if (res.code == 200) {
          this.serve = res.data.qybList
          this.serveNoUse = this.serve.filter(item => item.activationCodeState != 1)
          this.serveNoUse = this.serveNoUse.map(item => {
            return { ...item, disabled: false }
          })
          this.serveUse = this.serve.filter(item => item.activationCodeState != 0)
          this.card = res.data.qykList
          this.cardNoUse = this.card.filter(item => item.activationCodeState != 1)
          this.cardUse = this.card.filter(item => item.activationCodeState != 0)

          this.cardNoUse = this.cardNoUse.map((item) => {
            return { ...item, disabled: false }
          })
          // console.log(this.cardNoUse, ' this.cardNoUse',)

        }

      })
    },
    back () {
      this.$router.push({ name: 'Mine' })

    },
    todetail (item1) {
      this.$router.push({ name: 'equityDatail', query: { id: item1.id } })
    },
    changeCheck () {
      this.check = true
    },
    changeCheck1 () {
      this.check = false
    },
    // 去激活
    toactive (id, iscard) {
      var that = this
      Dialog.confirm({
        title: '',
        message: '是否确认激活',
      })
        .then(() => {
          // on confirm
          that.show = true
          if (iscard == 0) {

            this.serveNoUse = this.serveNoUse.map((item) => {

              if (item.activationCodeId == id) {
                console.log({ ...item, disabled: true }, 'p')

                return { ...item, disabled: true }
              }
              return { ...item }
            })
          } else {
            this.cardNoUse = this.cardNoUse.map((item) => {
              if (item.activationCodeId == id) {

                return { ...item, disabled: true }
              }
              return { ...item }
            })
          }


          this.activequityCenter({ activationCodeId: id }).then(res => {
            that.show = false
            if (res.code == 200) {

              Dialog.alert({
                title: '',
                message: '激活成功',
              }).then(() => {
                // on close
                that.getList()
                setTimeout(() => {

                  if (iscard == 0) {
                    that.serveNoUse = that.serveNoUse.map((item) => {
                      if (item.activationCodeId == id) {
                        return { ...item, disabled: false }
                      }
                      return { ...item }
                    })
                  } else {
                    that.cardNoUse = that.cardNoUse.map((item) => {
                      if (item.activationCodeId == id) {
                        return { ...item, disabled: false }
                      }
                      return { ...item }
                    })
                  }
                }, 500)
              })


            } else if (res.code == 1001) {
              Dialog.alert({
                title: '',
                message: '请先进行实名认证',
              }).then(() => {
                // on close
                if (iscard == 0) {
                  that.serveNoUse = that.serveNoUse.map((item) => {
                    if (item.activationCodeId == id) {
                      return { ...item, disabled: false }
                    }
                    return { ...item }
                  })
                } else {
                  that.cardNoUse = that.cardNoUse.map((item) => {
                    if (item.activationCodeId == id) {
                      return { ...item, disabled: false }
                    }
                    return { ...item }
                  })
                }
                that.$router.push({ name: 'card' })
              })


            } else {
              Toast(res.msg)
              if (iscard == 0) {
                that.serveNoUse = that.serveNoUse.map((item) => {
                  if (item.activationCodeId == id) {
                    return { ...item, disabled: false }
                  }
                  return { ...item }
                })
              } else {
                that.cardNoUse = that.cardNoUse.map((item) => {
                  if (item.activationCodeId == id) {
                    return { ...item, disabled: false }
                  }
                  return { ...item }
                })
              }
            }

          })

        })
        .catch(() => {
          // on cancel
        })



    }

  },
}
